(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("babylonjs"), require("babylonjs-materials"), require("babylonjs"), require("babylonjs-gui"), require("babylonjs-gui-editor"), require("babylonjs-loaders"), require("babylonjs-serializers"));
	else if(typeof define === 'function' && define.amd)
		define("babylonjs-inspector", ["babylonjs", "babylonjs-materials", "babylonjs", "babylonjs-gui", "babylonjs-gui-editor", "babylonjs-loaders", "babylonjs-serializers"], factory);
	else if(typeof exports === 'object')
		exports["babylonjs-inspector"] = factory(require("babylonjs"), require("babylonjs-materials"), require("babylonjs"), require("babylonjs-gui"), require("babylonjs-gui-editor"), require("babylonjs-loaders"), require("babylonjs-serializers"));
	else
		root["INSPECTOR"] = factory(root["BABYLON"], root["BABYLON"], root["BABYLON"]["Debug"], root["BABYLON"]["GUI"], root["BABYLON"], root["BABYLON"], root["BABYLON"]);
})((typeof self !== "undefined" ? self : typeof global !== "undefined" ? global : this), (__WEBPACK_EXTERNAL_MODULE__1520__, __WEBPACK_EXTERNAL_MODULE__7849__, __WEBPACK_EXTERNAL_MODULE__4352__, __WEBPACK_EXTERNAL_MODULE__1213__, __WEBPACK_EXTERNAL_MODULE__7077__, __WEBPACK_EXTERNAL_MODULE__6190__, __WEBPACK_EXTERNAL_MODULE__9545__) => {
return 